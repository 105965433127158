import { Spin } from "antd";
import React, { useEffect } from "react";
import { UserType } from "../../../types";
import styled from '@emotion/styled'
import styles from './index.module.sass'
import { LoadingOutlined } from "@ant-design/icons";
import { useI18n } from "@9troisquarts/wand";
import httpPost from "../../../../utils/httpPost";

const Layout = styled.div`
  display: block;
  text-align: center;
  height: 100vh;
  background-color: white;
`

type SignInProps = {
  user: UserType;
  errors: any;
};

const SignIn: React.FC<SignInProps> = props => {

  const { t } = useI18n()

  useEffect(() => {
    httpPost('/users/auth/keycloak', {})
  }, [])

  /*
   * Render
   */

  return (
    <Layout>
      <div className={styles.componentContainer}>
        <div className="h1-regular">
          { t('words.loading') }
        </div>
        <Spin
          size="large"
          indicator={<LoadingOutlined />}
        />
      </div>
    </Layout>
  )
};

export default SignIn